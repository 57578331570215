import { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useParams } from 'react-router';


import { DeleteButton, ModalContentTypo } from '../../Components/common/CommonElement';


import { api__getGoodTalkPlayList, api__postGoodTalkStop } from '../../services/ArtistPageService';
import { GoodTalkPlayInfo, GoodTalkPlayListInfo } from '../../Utils/types';
import { addCommas, detailTimeFormat } from '../../Utils/utils';
import { ROUTES } from '../../Utils/routes';

import CommonTable from '../../Components/CommonTable';
import ModalMsgBox from '../../Components/common/ModalMsgBox';

export default function GoodTalksPlay() {
  const { limit } = useParams();
  const [dataListInfo, setDataListInfo] = useState<GoodTalkPlayListInfo | null>(null);
  const [deleteModal, setDeleteModal] = useState<GoodTalkPlayInfo | null>(null);

  return (
    <>
      {deleteModal && (
        <ModalMsgBox title={`${deleteModal.title} 굿톡을 중단하시겠습니까?`} setModal={(bool) => {
          setDeleteModal(null);
          if (!bool) {
            api__postGoodTalkStop(deleteModal.channel).then((res) => {
              if (res.code === 'S200') {
                setTimeout(() => {
                  window.location.reload();
                }, 1000)
              }
            });
          }
        }} extraBtn={'중단'}>
          <ModalContentTypo>
            ㆍ아티스트명 : {deleteModal.nameKo}<br />
            ㆍ닉네임 : {deleteModal.nickName}<br />
            ㆍ굿톡 제목 : {deleteModal.title}
          </ModalContentTypo>
        </ModalMsgBox >
      )
      }
      <CommonTable
        dataListInfo={dataListInfo}
        setDataListInfo={setDataListInfo}
        hasArtistList

        deleteModal={null}
        setDeleteModal={() => { }}
        deleteCol="goodTalkId"
        deleteDesc="title"

        mainTitle='아티스트 페이지 관리 / 굿톡 실황'
        pageTitle={`굿톡 실황 / 게시물 수 : ${addCommas(dataListInfo?.totalCount || 0)}개`}
        route={ROUTES.GOODTALKS_PLAY_MAIN}

        getApiFn={api__getGoodTalkPlayList}
        delApiFn={() => { }}

        ths={[
          { name: "#", width: 40 },
          { name: "아티스트명", width: 150 },
          { name: "계정 닉네임", width: 150 },
          { name: "제목", width: 200 },
          { name: "현재 상태", width: 80 },
          { name: "시작 일시", width: 120 },
          { name: "현재 참여자 수", width: 80 },
          { name: "관리", width: 120 },
        ]}
      >
        <TableBody>
          {dataListInfo && dataListInfo.data.map((item, uidx) => (
            <TableRow key={`user-${uidx}`}>
              <TableCell>{(dataListInfo.currentPage - 1) * Number(limit) + uidx + 1}</TableCell>
              <TableCell>{item.nameKo}</TableCell>
              <TableCell>{item.nickName}</TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell>{item.status === '001' ? '굿톡중' : "굿톡 종료"}</TableCell>
              <TableCell>{detailTimeFormat(item.startDt)}</TableCell>
              <TableCell>{addCommas(item.joinCount)}</TableCell>
              <TableCell>
                <DeleteButton onClick={() => { setDeleteModal(item) }}>중단</DeleteButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </CommonTable>
    </>
  )
}