import axios from "axios";
import { makeHeader } from "../Utils/utils";
import { getAxiosProcess, postAxiosProcess } from "./CommonService";

const rootUrl = process.env.REACT_APP_ENV === "LOCAL" ? "" : process.env.REACT_APP_API_URL;

// 아티스트 페이지 관리 - 아티스트 피드 리스트 불러오기
export const api__getArtistFeedList = async (page: string, limit: string, artistId: string, membershipContentsState: string) => {
  try {
    const url = `${rootUrl}/api/artist-feeds?${!artistId ? "" : `artistId=${artistId}`}&page=${page}&limit=${limit}${
      membershipContentsState ? `&membershipContentsState=${membershipContentsState}` : ""
    }`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getArtistFeedList error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 아티스트 피드 삭제
export const api__delArtistFeed = async (artistFeedId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/artist-feeds/${artistFeedId}`, makeHeader());
  } catch (error) {
    console.log("delArtistFeed error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 아티스트 피드 상세보기
export const api__getArtistFeedInfo = async (artistFeedId: string) => {
  try {
    const url = `${rootUrl}/api/artist-feeds/${artistFeedId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getArtistFeedInfo error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿덕 사서함 리스트 불러오기
export const api__getMailBoxesList = async (page: string, limit: string, artistId: string, membershipContentsState: string) => {
  try {
    const url = `${rootUrl}/api/mail-boxes?page=${page}&limit=${limit}${!artistId ? "" : `&artistId=${artistId}`}
    ${membershipContentsState ? `&membershipContentsState=${membershipContentsState}` : ""}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getMailBoxesList error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿덕 사서함 정보 가져오기
export const api__getMailBoxInfo = async (mailBoxId: string) => {
  try {
    const url = `${rootUrl}/api/mail-boxes/${mailBoxId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getMailBoxInfo error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿덕 사서함 삭제
export const api__delMailBoxes = async (mailBoxId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/mail-boxes/${mailBoxId}`, makeHeader());
  } catch (error) {
    console.log("delMailBoxes error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿덕 사서함 디폴트 이미지 정보 가져오기
export const api__getMailBoxDefaultImages = async () => {
  try {
    return await axios.get(`${rootUrl}/api/mail-box-imgs`, makeHeader());
  } catch (error) {
    console.log("getMailBoxDefaultImages error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿덕 사서함 디폴트 이미지 정보 업로드하기
export const api__postMailBoxDefaultImages = async (imgUrlList: string[]) => {
  try {
    return await axios.post(`${rootUrl}/api/mail-box-imgs`, { imgUrlList }, makeHeader());
  } catch (error) {
    console.log("postMailBoxDefaultImages error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿덕 사서함 디폴트 이미지 정보 업로드하기
export const api__putMailBoxDefaultImages = async (imgUrlList: any[]) => {
  try {
    return await axios.put(`${rootUrl}/api/mail-box-imgs`, imgUrlList, makeHeader());
  } catch (error) {
    console.log("putMailBoxDefaultImages error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿덕 사서함 디폴트 이미지 정보 삭제하기
export const api__delMailBoxDefaultImages = async (imgSeq: number) => {
  try {
    return await axios.delete(`${rootUrl}/api/mail-box-imgs/${imgSeq}`, makeHeader());
  } catch (error) {
    console.log("delMailBoxDefaultImages error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿톡 리스트 불러오기
export const api__getGoodTalksList = async (page: string, limit: string, artistId: string) => {
  try {
    const url = `${rootUrl}/api/good-talks?page=${page}&limit=${limit}${!artistId ? "" : `&artistId=${artistId}`}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getGoodTalksList error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿톡 정보 가져오기
export const api__getGoodTalkInfo = async (goodTalkId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/good-talks/${goodTalkId}`, makeHeader());
  } catch (error) {
    console.log("getGoodTalkInfo error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿톡 삭제
export const api__delGoodTalks = async (goodTalkId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/good-talks/${goodTalkId}`, makeHeader());
  } catch (error) {
    console.log("delGoodTalks error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 굿톡 재생 목록
export const api__getGoodTalkPlayList = async (page: string, limit: string, artistId: string) => {
  try {
    const url = `${rootUrl}/api/goodtalk/stop-list?page=${page}&limit=${limit}${!artistId ? "" : `&artistId=${artistId}`}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getGoodTalkPlayList error ::: " + error);
    return null;
  }
};
export const api__postGoodTalkStop = async (channelId: string) => {
  try {
    const url = `${rootUrl}/api/goodtalk/stop?channelId=${channelId}`;
    return postAxiosProcess(url, {});
  } catch (error) {
    console.log("postGoodTalkStop error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 갤러리 리스트 불러오기
export const api__getGalleriesList = async (page: string, limit: string, artistId: string) => {
  try {
    const url = `${rootUrl}/api/galleries?page=${page}&limit=${limit}${!artistId ? "" : `&artistId=${artistId}`}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getGalleriesList error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 갤러리 정보 가져오기
export const api__getGalleryInfo = async (galleryId: string) => {
  try {
    const url = `${rootUrl}/api/galleries/${galleryId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getGalleryInfo error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 갤러리 삭제
export const api__delGalleries = async (galleryId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/galleries/${galleryId}`, makeHeader());
  } catch (error) {
    console.log("delGalleries error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 응원글 리스트 불러오기
export const api__getCheerUpList = async (page: string, limit: string, artistId: string) => {
  try {
    const url = `${rootUrl}/api/cheer-up?page=${page}&limit=${limit}${!artistId ? "" : `&artistId=${artistId}`}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getCheerUpList error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 응원글 상세 (신고용)
export const api__getCheerUp = async (cheerUpId: string) => {
  try {
    return await axios.get(`${rootUrl}/api/cheer-up/${cheerUpId}`, makeHeader());
  } catch (error) {
    console.log("api__getCheerUp error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 응원글 삭제
export const api__delCheerUp = async (cheerUpId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/cheer-up/${cheerUpId}`, makeHeader());
  } catch (error) {
    console.log("delCheerUp error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 팬끼리 리스트 불러오기
export const api__getFanOnlyList = async (page: string, limit: string, artistId: string) => {
  try {
    const url = `${rootUrl}/api/fan-only?page=${page}&limit=${limit}${!artistId ? "" : `&artistId=${artistId}`}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getFanOnlyList error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 팬끼리 삭제
export const api__delFanOnly = async (fanOnlyId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/fan-only/${fanOnlyId}`, makeHeader());
  } catch (error) {
    console.log("delFanOnly error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 팬끼리 상세보기
export const api__getFanOnlyDetail = async (fanOnlyId: string) => {
  try {
    const url = `${rootUrl}/api/fan-only/${fanOnlyId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getFanOnlyDetail error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 자유게시판 리스트 불러오기
export const api__getInfoRoomsList = async (page: string, limit: string, artistId: string) => {
  try {
    const url = `${rootUrl}/api/info-rooms?page=${page}&limit=${limit}${!artistId ? "" : `&artistId=${artistId}`}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getInfoRoomsList error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 자유게시판 삭제
export const api__delInfoRooms = async (infoRoomId: string) => {
  try {
    return await axios.delete(`${rootUrl}/api/info-rooms/${infoRoomId}`, makeHeader());
  } catch (error) {
    console.log("delInfoRooms error ::: " + error);
    return null;
  }
};

// 아티스트 페이지 관리 - 자유게시판 상세보기
export const api__getInfoRoomsDetail = async (infoRoomId: string) => {
  try {
    const url = `${rootUrl}/api/info-rooms/${infoRoomId}`;
    return getAxiosProcess(url);
  } catch (error) {
    console.log("getInfoRoomsDetail error ::: " + error);
    return null;
  }
};
