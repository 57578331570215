export const ROOTROUTES = {
  PARTNERS: "/partners",
  TERMS: "/terms",
  USERMANAGE: "/manage",
  HOMEFEED: "/home-post",
  ARTISTPAGE: "/artist-page",
  CALENDAR: "/cal",
  BADGE: "/badge",
  REPORT: "/report",
  REPLY: "/reply",
};

export const ROUTES = {
  PARTNERS_ACCOUNT: `${ROOTROUTES.PARTNERS}/account`,
  PARTNERS_NOTICE: `${ROOTROUTES.PARTNERS}/notice`,
  PARTNERS_TERMS: `${ROOTROUTES.PARTNERS}/use-terms`,
  PARTNERS_PERSONAL: `${ROOTROUTES.PARTNERS}/personal-terms`,

  SERVICE_TERMS: `${ROOTROUTES.TERMS}/service`,
  PERSONAL_TERMS: `${ROOTROUTES.TERMS}/personal`,
  RECOMMEND_TERMS: `${ROOTROUTES.TERMS}/recommend`,

  SIGNUP_TERMS: `${ROOTROUTES.TERMS}/signup`,
  TELAUTH_TERMS: `${ROOTROUTES.TERMS}/tel-auth`,
  PAYMENT_TERMS: `${ROOTROUTES.TERMS}/payment`,
  ORDER_TERMS: `${ROOTROUTES.TERMS}/order`,

  SIGNUPUSERS: `${ROOTROUTES.USERMANAGE}/users`,
  SIGNUPARTIST: `${ROOTROUTES.USERMANAGE}/artist`,

  GOODNEWS_MAIN: `${ROOTROUTES.HOMEFEED}/good-news`,
  GOODDUCKMAGAZINE_MAIN: `${ROOTROUTES.HOMEFEED}/goodduck-magazine`,

  ARTISTFEED_MAIN: `${ROOTROUTES.ARTISTPAGE}/feed`,
  MAILBOXES_MAIN: `${ROOTROUTES.ARTISTPAGE}/mail-boxes`,
  MAILBOXES_DEFAULT: `${ROOTROUTES.ARTISTPAGE}/mail-boxes/default`,
  GOODTALKS_MAIN: `${ROOTROUTES.ARTISTPAGE}/good-talks`,
  GOODTALKS_PLAY_MAIN: `${ROOTROUTES.ARTISTPAGE}/good-play`,
  GALLERY_MAIN: `${ROOTROUTES.ARTISTPAGE}/galleries`,
  CHEERUP_MAIN: `${ROOTROUTES.ARTISTPAGE}/cheer-up`,
  FANONLY_MAIN: `${ROOTROUTES.ARTISTPAGE}/fan-only`,
  INFOROOM_MAIN: `${ROOTROUTES.ARTISTPAGE}/info-room`,

  CALENDAR_MAIN: `${ROOTROUTES.CALENDAR}/calendar`,
  TIPOFFSCHEDULE_MAIN: `${ROOTROUTES.CALENDAR}/tip-off-schedule`,

  BADGE_MAIN: `${ROOTROUTES.BADGE}`,

  REPLY_MAIN: `${ROOTROUTES.REPLY}`,

  COMMENT_REPORT_MAIN: `${ROOTROUTES.REPORT}/comment`,
  BOARD_REPORT_MAIN: `${ROOTROUTES.REPORT}/board`,
};
