import { useContext, useState } from 'react';
import { Box } from "@mui/material";
import { BackBtn, DCBox, FlexBox, MainButton, ModalContentTypo, PageTitle, SBCBox } from "../common/CommonElement";
import { COLORS } from "../../Constant/COLORS";
import ModalMsgBox from '../common/ModalMsgBox';
import { ArtistDetailContext } from '../../pages/manage/ArtistDetail';

type IProps = {
  updateActivation: Function;
  editActivate: () => void;
}

export default function ArtistDetailHeader({ editActivate, updateActivation }: IProps) {
  const { editMode, basicInfo } = useContext(ArtistDetailContext);
  const [activeModal, setActiveModal] = useState('');

  return <>
    {activeModal !== '' && (
      <ModalMsgBox
        title={`해당 아티스트를 ${activeModal === 'active' ? '' : '비'}활성화 하시겠어요?`}
        setModal={(bool) => {
          setActiveModal('');
          if (!bool) {
            updateActivation(activeModal === 'active' ? "Y" : "N");
          }
        }} extraBtn={activeModal === 'active' ? '활성화' : '비활성화'}>
        {basicInfo?.artistUseYn === 'Y' ?
        <ModalContentTypo>
          <ul>
            <li>
              비활성화 조건
              <ul>
                <li>멤버십 : 비활성화 상태</li>
                <li>소속사 관리 아티스트 : 제외된 상태</li>
              </ul>
            </li>
            <li>
              비활성화 동작
              <ul>
                <li>앱에서 해당 아티스트에는 더이상 접근이 불가능해요.</li>
                <li>아티스트/관리자 계정은 모두 비활성화 처리 되어요.</li>
                <li>입덕 프로필 데이터는 모두 유지되어요.</li>
              </ul>
            </li>
          </ul>
        </ModalContentTypo>
        :
        <ModalContentTypo>
          <ul>
            <li>활성화 동작
              <ul>
                <li>앱에서 해당 아티스트에는 다시 접근이 가능해요.</li>
                <li>아티스트/관리자 계정은 모두 활성화 처리 되어요.</li>
                <li>기존에 입덕해 있던 프로필들은 다시 해당 아티스트와 관련된 데이터에 접근이 가능해요(게시물, 댓글 등)</li>
              </ul>
            </li>
          </ul>
        </ModalContentTypo>
      }
      </ModalMsgBox>
    )}
    <SBCBox p="38px 34px 12px 34px">
      <Box>
        <DCBox mb="29px">
          <BackBtn />
          <PageTitle>아티스트 상세 관리(상태: {basicInfo?.artistUseYn === "Y" ? "활성화" : "비활성화"})</PageTitle>
        </DCBox>
      </Box>
      <FlexBox>
        <MainButton sx={{ mr: '16px' }} style={basicInfo?.artistUseYn === "Y" ? { backgroundColor: COLORS.BORDER_GRAY, color: '#222' } : {}} onClick={() => { setActiveModal(basicInfo?.artistUseYn === "Y" ? "inactive" : "active") }}>
          {basicInfo?.artistUseYn === "Y" ? "아티스트 비활성화" : "아티스트 활성화"}
        </MainButton>
        <MainButton onClick={editActivate}>{editMode ? "수정 적용" : "수정"}</MainButton>
      </FlexBox>
    </SBCBox>
  </>
}