import { ROUTES } from "../Utils/routes";
import ArtistFeed from "../pages/artist-feed";
import ArtistFeedDetail from "../pages/artist-feed/ArtistFeedDetail";
import CheerUp from "../pages/cheer-up";
import FanOnly from "../pages/fan-only";
import FanOnlyDetail from "../pages/fan-only/FanOnlyDetail";
import Galleries from "../pages/galleries";
import GalleryDetail from "../pages/galleries/GalleryDetail";
import GoodTalksPlay from "../pages/good-play";
import GoodTalks from "../pages/good-talks";
import GoodTalkReview from "../pages/good-talks/GoodTalkReview";
import InfoRoom from "../pages/info-rooms";
import InfoRoomsDetail from "../pages/info-rooms/InfoRoomsDetail";
import MailBoxes from "../pages/mail-boxes";
import MailBoxDefaultImage from "../pages/mail-boxes/MailBoxDefaultImages";
import MailBoxDetail from "../pages/mail-boxes/MailBoxDetail";

export const artistPageMenuList = [
  { path: ROUTES.ARTISTFEED_MAIN + "/:page/:limit/:artistId?", component: ArtistFeed },
  { path: ROUTES.ARTISTFEED_MAIN + "/:artistFeedId", component: ArtistFeedDetail },
  { path: ROUTES.MAILBOXES_MAIN + "/:page/:limit/:artistId?", component: MailBoxes },
  { path: ROUTES.MAILBOXES_MAIN + "/:mailBoxId", component: MailBoxDetail },
  { path: ROUTES.MAILBOXES_DEFAULT, component: MailBoxDefaultImage },
  { path: ROUTES.GOODTALKS_MAIN + "/:page/:limit/:artistId?", component: GoodTalks },
  { path: ROUTES.GOODTALKS_PLAY_MAIN + "/:page/:limit/:artistId?", component: GoodTalksPlay },
  { path: ROUTES.GOODTALKS_MAIN + "/:goodTalkId", component: GoodTalkReview },
  { path: ROUTES.GALLERY_MAIN + "/:page/:limit/:artistId?", component: Galleries },
  { path: ROUTES.GALLERY_MAIN + "/:galleryId", component: GalleryDetail },
  { path: ROUTES.CHEERUP_MAIN + "/:page/:limit/:artistId?", component: CheerUp },
  { path: ROUTES.FANONLY_MAIN + "/:page/:limit/:artistId?", component: FanOnly },
  { path: ROUTES.FANONLY_MAIN + "/:fanOnlyId", component: FanOnlyDetail },
  { path: ROUTES.INFOROOM_MAIN + "/:page/:limit/:artistId?", component: InfoRoom },
  { path: ROUTES.INFOROOM_MAIN + "/:infoRoomId", component: InfoRoomsDetail },
]